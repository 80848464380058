import { Box, Container } from '@mui/system';
import CustomCareerJobItem from 'src/AppComponents/CustomCareerJobItem';
import { Page } from 'src/components';
import React from 'react';
import ProjectDetailModal from 'src/AppComponents/ProjectDetailModal';

const JobDetails = {
  id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
  companyName: 'Lueilwitz and Sons',
  companyLogo: 'https://zone-assets-api.vercel.app/assets/images/companies/company_1.png',
  slug: 'Marketing Coordinator',
  category: 'Design',
  createdAt: '2022-12-29T09:15:14.323Z',
  deadline: '2023-01-29T09:15:14.323Z',
  location: 'Kenya',
  experience: 1,
  type: 'full time',
  level: 'No experience',
  salary: 'competitive',
  languages: ['Russian', 'Spanish', 'English'],
  isUrgent: false,
  views: 286,
  favorited: false,
  skills: ['Photography', 'Design', 'Art', 'History', 'Museums', 'Creativity', 'Art History'],
  locationMap: [
    {
      address: '19034 Verna Unions Apt. 164 - Honolulu, RI / 87535',
      phoneNumber: '365-374-4961',
      email: 'nannie_abernathy70@yahoo.com',
      latlng: [33, 65],
    },
  ],
  otherBenefits: [
    'Free parking',
    'Bonus commission',
    'Travel',
    'Training',
    'Device support',
    'Health care',
  ],
  content:
    '\n<h4 id="one">Job Description</h4>\n<p>Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut. Est quidem iusto praesentium excepturi harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium doloribus eaque debitis.</p>\n<h4 id="two">Key Responsibilitiess</h4>\n<ul>\n  <li>Working with agency for design drawing detail, quotation and local production.</li>\n  <li>Produce window displays, signs, interior displays, floor plans and special promotions displays.</li>\n  <li>Change displays to promote new product launches and reflect festive or seasonal themes.</li>\n  <li>Planning and executing the open/renovation/ closing store procedure.</li>\n  <li>Follow‐up store maintenance procedure and keep updating SKU In &amp; Out.</li>\n  <li>Monitor costs and work within budget.</li>\n  <li>Liaise with suppliers and source elements.</li>\n</ul>\n<h4 id="three">Why You\'ll Love Working Here</h4>\n<ul>\n  <li>Working with agency for design drawing detail, quotation and local production.</li>\n  <li>Produce window displays, signs, interior displays, floor plans and special promotions displays.</li>\n  <li>Change displays to promote new product launches and reflect festive or seasonal themes.</li>\n  <li>Planning and executing the open/renovation/ closing store procedure.</li>\n  <li>Follow‐up store maintenance procedure and keep updating SKU In &amp; Out.</li>\n  <li>Monitor costs and work within budget.</li>\n  <li>Liaise with suppliers and source elements.</li>\n</ul>\n',
  shareLinks: {
    facebook: 'facebook/user-name',
    instagram: 'instagram/user-name',
    linkedin: 'linkedin/user-name',
    twitter: 'twitter/user-name',
  },
};

function DashboardFreelancer() {
  // const { data: jobs = [], error } = useRequest({
  //   url: `/api/career/jobs`,
  // });

  const openModal = () => {
    alert('abc');
  };

  return (
    <Page sx={{ py: 6 }} title="Jobs - Career">
      <Container>
        {/* <CareerJobList jobs={jobs} loading={!jobs.length && !error} /> */}

        <ProjectDetailModal />

        <Box
          sx={{
            display: 'grid',
            rowGap: { xs: 4, md: 5 },
            columnGap: 4,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            },
          }}
        >
          {new Array(9).fill('').map((_, i) => (
            <CustomCareerJobItem key={i} asButton={true} onClick={openModal} job={JobDetails} />
          ))}
        </Box>
      </Container>
    </Page>
  );
}

export default DashboardFreelancer;
