import { StoreConst } from 'src/store/local';
import useRole from 'src/hooks/useRole';
import DashboardFreelancer from 'src/sections/@dashboard/freelancer';
import { DashboardClient } from 'src/sections/@dashboard';

// ----------------------------------------------------------------------

function Dashboard() {
  const isClient = useRole(StoreConst.CLIENT);
  return <>{isClient ? <DashboardClient /> : <DashboardFreelancer />}</>;
}

export default Dashboard;
