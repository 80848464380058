import {
  AppBar,
  Avatar,
  Button,
  Chip,
  Dialog,
  Divider,
  IconButton,
  Rating,
  Slide,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box, Container } from '@mui/system';
import CustomCareerJobItem from 'src/AppComponents/CustomCareerJobItem';
import { Iconify, Page, ShareButton } from 'src/components';
import { useRequest } from 'src/hooks';
import { CareerJobList } from 'src/sections/@career';
import React from 'react';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';
import { Icon } from '@iconify/react';
import CheckBoxIconButton from './CheckBoxIconButton';
import { useDispatch, useSelector } from 'react-redux';
import { setModalVisibility } from 'src/reducers/jobDetailModalSlice';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function ProjectDetailModal() {
  const { isModalVisible } = useSelector((state) => state.jobDetailModalSlice);
  const isGreatorThanSM = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setModalVisibility(false));
  };

  return (
    <Dialog
      fullScreen
      open={isModalVisible}
      onClose={closeModal}
      sx={{ maxWidth: '800px', right: 0, ml: 'auto' }}
      PaperProps={{ sx: { border: 0 } }}
      TransitionComponent={Transition}
      scroll="body"
    >
      <AppBar position="relative" sx={{ border: 0 }}>
        <Toolbar>
          <IconButton onClick={closeModal} color="inherit" edge="start" sx={{ mr: 1 }}>
            <Iconify
              icon="ic:round-arrow-back-ios-new"
              sx={{ fontSize: { xs: '1rem', sm: 'inherit' } }}
            />
          </IconButton>

          <Typography variant="body1" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>
            Job Details
          </Typography>

          <Stack justifyContent="end" direction="row" flex={1} spacing={1}>
            <Stack direction="row" alignItems="center">
              <CheckBoxIconButton
                icon={<Iconify icon="ant-design:like-outlined" sx={{ color: 'white' }} />}
                checkedIcon={<Iconify icon="ant-design:like-filled" sx={{ color: 'white' }} />}
              />
              <CheckBoxIconButton
                icon={<Iconify icon="ant-design:dislike-outlined" sx={{ color: 'white' }} />}
                checkedIcon={<Iconify icon="ant-design:dislike-filled" sx={{ color: 'white' }} />}
              />
            </Stack>

            <ShareButton icon="jam:share-alt" sx={{ color: 'white' }} width={22} height={22} />
          </Stack>
        </Toolbar>
      </AppBar>

      <Box sx={{ py: { xs: 3, sm: 4 }, px: { xs: 3, sm: 5 } }}>
        <Box mb={5}>
          <Typography variant="h4" color="grey.700" mb={2}>
            Need AI/ML developer for Medical Application
          </Typography>

          <Stack spacing={1} mb={3} alignItems="start">
            <Stack spacing={1} direction="row" alignItems="center" flexWrap="wrap" color="grey.700">
              <Typography component="span">Elon Musk,</Typography>
              <Typography component="span">California,</Typography>

              <Stack direction="row" alignItems="center" spacing={0.6}>
                <Typography component="span">US</Typography>
                <Iconify
                  icon="material-symbols:verified-outline-rounded"
                  sx={{ color: 'inherit', position: 'relative', bottom: 0.9 }}
                />
              </Stack>
            </Stack>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 4, sm: 0 }}
              justifyContent="space-between"
              width="100%"
            >
              <Box>
                <Typography lineHeight={1} color="grey.700" mb={2}>
                  1 Day ago, 30 Applicants
                </Typography>
                <Typography lineHeight={1} color="grey.700">
                  Immediate, Expert
                </Typography>
              </Box>
              <Box>
                <Typography
                  lineHeight={1}
                  textAlign={{ xs: 'left', sm: 'right' }}
                  color="grey.700"
                  mb={0.2}
                >
                  Budget
                </Typography>
                <Typography lineHeight={1} textAlign={{ xs: 'left', sm: 'right' }} color="grey.700">
                  <Typography
                    fontSize={22}
                    variant="body1"
                    component="span"
                    color="primary.main"
                    fontWeight={700}
                  >
                    $100
                  </Typography>{' '}
                  USD
                </Typography>
              </Box>
            </Stack>
          </Stack>

          <Stack spacing={2} alignItems="start">
            <Stack direction="row" spacing={1.2} sx={{ width: '100%' }}>
              <Button sx={{ flex: { xs: '1', sm: 'unset' } }} variant="contained">
                Post Proposal
              </Button>
              <Button sx={{ flex: { xs: '1', sm: 'unset' } }} variant="outlined">
                Save Job
              </Button>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={0.6}>
              <Iconify icon="typcn:tick" sx={{ color: 'success.main', fontSize: 26 }} />
              <Typography color="success.dark">Applied</Typography>
            </Stack>
          </Stack>
        </Box>

        <Box border={2} borderColor="grey.400" borderRadius={1} py={2} px={3} mb={4}>
          <Typography>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Porro non modi iste neque
            reprehenderit temporibus nostrum autem sunt, distinctio quam laboriosam expedita, aut
            eaque, molestiae eum totam ad! Ex, reiciendis!
          </Typography>
        </Box>

        <Stack direction="row" mx={-0.8} my={-0.8} flexWrap="wrap">
          <Chip
            label="HTML"
            sx={{ m: 0.8, borderColor: 'grey.600', color: 'grey.800' }}
            variant="outlined"
          />
          <Chip
            label="AI/ML"
            sx={{ m: 0.8, borderColor: 'grey.600', color: 'grey.800' }}
            variant="outlined"
          />
          <Chip
            label="Jupyter"
            sx={{ m: 0.8, borderColor: 'grey.600', color: 'grey.800' }}
            variant="outlined"
          />
        </Stack>

        <Divider sx={{ my: 4, borderColor: 'grey.400' }} />

        <Box mb={8}>
          <Typography variant="h5" mb={5}>
            About Client
          </Typography>

          <Box>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 3.5 }} mb={5}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar sx={{ width: { xs: 80, sm: 100 }, height: { xs: 80, sm: 100 } }} />

                {!isGreatorThanSM && (
                  <Box>
                    <Typography variant="h5" lineHeight={1} mb={1.2}>
                      Anil Kumar
                    </Typography>
                    <Typography variant="subtitle2" lineHeight={1} mb={1.4}>
                      anikumar
                    </Typography>
                    <Typography variant="body2" color="grey.600" lineHeight={1}>
                      Bangalore, India
                    </Typography>
                  </Box>
                )}
              </Stack>

              <Box flex={1}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2.4}
                  alignItems={{ xs: 'unset', sm: 'center' }}
                  justifyContent="space-between"
                >
                  {isGreatorThanSM && (
                    <Box>
                      <Typography variant="h5" lineHeight={1} mb={1.2}>
                        Anil Kumar
                      </Typography>
                      <Typography variant="subtitle2" lineHeight={1} mb={1.4}>
                        anikumar
                      </Typography>
                      <Typography variant="body2" color="grey.600" lineHeight={1}>
                        Bangalore, India
                      </Typography>
                    </Box>
                  )}

                  <Stack
                    direction={{ xs: 'row', sm: 'column' }}
                    justifyContent="space-between"
                    pt={1.7}
                  >
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={1.4}
                      alignItems={{ xs: 'unset', sm: 'center' }}
                      mb={1}
                    >
                      <Rating name="read-only" size="small" value={3.5} precision={0.1} readOnly />
                      <Typography variant="body2" lineHeight={1}>
                        (5 Reviews)
                      </Typography>
                    </Stack>

                    <Stack>
                      <Typography variant="subtitle2" textAlign="right">
                        Total Earning
                      </Typography>
                      <Typography variant="subtitle1" textAlign="right">
                        $1K
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack direction="row" justifyContent="space-between" mt={{ xs: 2, sm: 4 }}>
                  <Box>
                    <Typography fontWeight={600} color="grey.500" fontSize={{ xs: 14, sm: 16 }}>
                      Last Seen
                    </Typography>
                    <Typography color="grey.700" fontSize={{ xs: 14, sm: 16 }}>
                      3 Hours ago
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      textAlign="right"
                      fontSize={{ xs: 14, sm: 16 }}
                      fontWeight={600}
                      color="grey.500"
                    >
                      Last Delivered
                    </Typography>
                    <Typography textAlign="right" fontSize={{ xs: 14, sm: 16 }} color="grey.700">
                      15 Days ago
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Stack>

            <Typography variant="h5" mb={3}>
              Experties In
            </Typography>

            <Stack direction="row" mx={-0.8} my={-0.8} flexWrap="wrap">
              {[
                'HTML',
                'CSS',
                'Python',
                'Mobile Development',
                'AWS',
                'Javascript',
                'Lua',
                'Python',
                'Mobile Development',
                'AWS',
                'Javascript',
                'Lua',
              ].map((skl, i) => (
                <Chip
                  key={i}
                  label={skl}
                  variant="outlined"
                  sx={{ m: 0.8, borderColor: 'grey.600', color: 'grey.800' }}
                />
              ))}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ProjectDetailModal;
